<template>
  <div class="sku-container" v-show="false">
    <div class="sku-print" id="print-b" ref="print">
      <div
        class="print-table"
        style="word-break: break-all; line-height: 1.5; font-family: 'Helvetica Neue'; box-sizing: border-box;"
      >
        <table style="width: 100%;">
          <tbody>
            <tr>
              <td
                colspan="16"
                style="font-weight: bold; position: relative; font-size: 14pt; height: 15mm; text-align: center"
              >
                深圳斯达领科科技有限公司大货工艺单
                <div style="position: absolute; right: 2mm; top: 2mm;">
                  <svg id="barcode" />
                </div>
              </td>
            </tr>
            <template v-if="info.skc">
              <tr>
                <td colspan="8" rowspan="6" style="text-align: left">
                  <img
                    :src="info.skc.image1"
                    style="width: 22%; aspect-ratio: 1; object-fit: contain; margin: 1%"
                  />
                  <img
                    :src="info.skc.image2"
                    style="width: 22%; aspect-ratio: 1; object-fit: contain; margin: 1%; outline: none"
                    v-if="info.skc.image2"
                  />
                  <img
                    :src="info.skc.image3"
                    style="width: 22%; aspect-ratio: 1; object-fit: contain; margin: 1%"
                    v-if="info.skc.image3"
                  />
                  <img
                    :src="info.skc.image4"
                    style="width: 22%; aspect-ratio: 1; object-fit: contain; margin: 1%"
                    v-if="info.skc.image4"
                  />
                </td>
                <td colspan="2">设计款号</td>
                <td colspan="6">{{info.skc.designCode}}</td>
              </tr>
              <tr>
                <td colspan="2">spu</td>
                <td colspan="6">{{info.skc.spuCode}}</td>
              </tr>
              <tr>
                <td colspan="2">品类</td>
                <td colspan="6">{{info.skc.categoryName}}</td>
              </tr>
              <tr>
                <td colspan="2">面料开发</td>
                <td colspan="6">{{info.skc.fabricTrackName}}</td>
              </tr>
              <tr>
                <td colspan="2">工艺师</td>
                <td colspan="6">{{info.skc.craftsmanName}}</td>
              </tr>
              <tr>
                <td colspan="2">二次工艺</td>
                <td colspan="6">{{info.skc.secondaryCraftNames}}</td>
              </tr>
              <tr>
                <td style="width: 15mm">备注</td>
                <td colspan="15" v-html="info.skc.remark"></td>
              </tr>
              <tr>
                <td colspan="16" style="font-weight: bold; text-align: center">尺寸要求</td>
              </tr>
            </template>
            <template v-if="info.sizeCodes">
              <template v-for="(item, index) in info.sizeCodesGroup">
                <tr :key="`sizeCodes-${index}-CategoryName`">
                  <td>款式品类</td>
                  <td colspan="3">{{item[0].sizeStyleCategoryName}}</td>
                  <td rowspan="2" style="color: red;">
                    <div style="width:29px!important">样衣尺寸</div>
                  </td>
                  <td colspan="10">洗水后尺码规格（CM）</td>
                  <td rowspan="2" style="width: 14mm; text-align: center">公差范围</td>
                </tr>
                <tr :key="`sizeCodes-${index}-name`">
                  <td>部位</td>
                  <td colspan="3">度量方法</td>
                  <td style="width: 10mm">{{info.sizeNames.sizeName1}}</td>
                  <td style="width: 10mm">{{info.sizeNames.sizeName2}}</td>
                  <td style="width: 10mm">{{info.sizeNames.sizeName3}}</td>
                  <td style="width: 10mm">{{info.sizeNames.sizeName4}}</td>
                  <td style="width: 10mm">{{info.sizeNames.sizeName5}}</td>
                  <td style="width: 10mm">{{info.sizeNames.sizeName6}}</td>
                  <td style="width: 10mm">{{info.sizeNames.sizeName7}}</td>
                  <td style="width: 10mm">{{info.sizeNames.sizeName8}}</td>
                  <td style="width: 10mm">{{info.sizeNames.sizeName9}}</td>
                  <td style="width: 10mm">{{info.sizeNames.sizeName10}}</td>
                </tr>
                <tr :key="`sizeCodes-${index}-sub-${i}`" v-for="(subitem, i) in item">
                  <td>{{subitem.sizePartName}}</td>
                  <td colspan="3">{{subitem.measurement}}</td>
                  <td style="color: red;">{{ subitem.sampleSize }}</td>
                  <td>{{subitem.value1}}</td>
                  <td>{{subitem.value2}}</td>
                  <td>{{subitem.value3}}</td>
                  <td>{{subitem.value4}}</td>
                  <td>{{subitem.value5}}</td>
                  <td>{{subitem.value6}}</td>
                  <td>{{subitem.value7}}</td>
                  <td>{{subitem.value8}}</td>
                  <td>{{subitem.value9}}</td>
                  <td>{{subitem.value10}}</td>
                  <td style="text-align: center">{{subitem.tolerance}}</td>
                </tr>
              </template>
            </template>
            <tr>
              <td colspan="16" style="font-weight: bold; text-align: center">物料信息</td>
            </tr>
            <tr>
              <td>物料项目</td>
              <td style="width: 18mm">图片</td>
              <td>
                <div style="min-width:80px!important">物料信息</div>
              </td>
              <td>
                <div style="width:30px!important">部位</div>
              </td>
              <td>
                <div style="width:29px!important">用量</div>
              </td>
              <td colspan="4">供应商信息</td>
              <td>
                <div style="width:29px!important">色卡名称</div>
              </td>
              <td>颜色</td>
              <td>色号</td>
              <td>
                <div style="width:29px!important">二次工艺</div>
              </td>
              <td colspan="3">备注</td>
            </tr>
            <template v-if="info.materialList">
              <tr :key="`materialList${index}`" v-for="(item, index) in info.materialList">
                <td>{{item.materialItem}}</td>
                <td>
                  <template v-if="item.colorCardImageUrl">
                    <img
                      :src="item.colorCardImageUrl"
                      style="width: 100%; aspect-ratio: 1; object-fit: contain"
                    />
                  </template>
                  <template v-else-if="item.purchaseImageUrls.length > 0">
                    <img
                      :src="item.purchaseImageUrls[0]"
                      style="width: 100%; aspect-ratio: 1; object-fit: contain"
                    />
                  </template>
                </td>
                <td style="text-align: left">
                  <template v-if="item.type===0">
                    <p>名称:{{item.materialName}}</p>
                    <p>克重(G):{{item.gramWeight}}</p>
                    <p>门幅(CM):{{item.larghezzaWidth}}</p>
                    <p>弹性:{{item.elasticityCategoryName}}</p>
                    <p>单价:{{item.price}}</p>
                    <p>分类:{{item.secondCategoryName}}</p>
                  </template>
                  <template v-else>
                    <p>名称:{{item.materialName}}</p>
                    <p>规格:{{item.specification}}</p>
                    <p>材质:{{item.subsidiaryIngredientName}}</p>
                    <p>单价:{{item.price}}</p>
                  </template>
                </td>
                <td>
                  <div style="width:30px!important">{{item.partUsedName}}</div>
                </td>
                <td>{{item.singleAmount}}</td>
                <td colspan="4" style="text-align: left">
                  <p>名称:{{item.simpleSupplierName}}</p>
                  <p>地址:{{item.supplierAddress}}</p>
                  <p>联系人:{{item.supplierContactName}}</p>
                  <p>电话:{{item.supplierPhone}}</p>
                  <p>微信:{{item.weChat}}</p>
                </td>
                <td>{{item.supplierMaterialName}}</td>
                <td>{{item.type===0?item.fabricColorName:item.auxiliaryColorName}}</td>
                <td>{{item.supplierSequence}}</td>
                <td>{{item.secondaryCraftNames}}</td>
                <td colspan="3">{{item.remarks}}</td>
              </tr>
            </template>
            <tr>
              <td colspan="16" style="font-weight: bold; text-align: center">工艺要求</td>
            </tr>
            <template v-for="(item, index) in info.crafts">
              <tr :key="`craftStyleCategoryName${index}`">
                <td style="font-weight: bold">款式品类</td>
                <td colspan="4">{{item.craftStyleCategoryName}}</td>
                <td colspan="11" />
              </tr>
              <tr :key="`specialCraft${index}`">
                <td>裁剪要求</td>
                <td colspan="15" style="white-space: pre-line;">{{item.specialCraft}}</td>
              </tr>
              <tr :key="`needleworkRequire${index}`">
                <td>针线要求</td>
                <td colspan="15" style="white-space: pre-line;">{{item.needleworkRequire}}</td>
              </tr>
              <tr :key="`ironRequire${index}`">
                <td>整烫要求</td>
                <td colspan="15" style="white-space: pre-line;">{{item.ironRequire}}</td>
              </tr>
              <tr :key="`packageRequire${index}`">
                <td>包装要求</td>
                <td colspan="15" style="white-space: pre-line;">{{item.packageRequire}}</td>
              </tr>
              <tr :key="`shippingMark${index}`">
                <td>唛头</td>
                <td colspan="15" style="white-space: pre-line;">{{item.shippingMark}}</td>
              </tr>
              <tr :key="`attentions${index}`">
                <td>样衣工艺及注意事项</td>
                <td colspan="15" style="white-space: pre-line;">{{item.attentions}}</td>
              </tr>
            </template>
            <template v-if="info.skc">
              <tr>
                <td colspan="12"></td>
                <td colspan="2">制单日期</td>
                <td colspan="2">{{info.skc.date}}</td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import JsBarcode from 'jsbarcode'
import SlPrint from '@/shared/util/printarea.js'

export default {
  data () {
    return {
      info: {},
      visible: false
    }
  },
  methods: {
    show (data) {
      const { crafts, materialList, sizeCodes: _sizeCodes, sizeNames, skc } = data
      data.crafts = crafts || []
      data.materialList = materialList || []
      data.sizeCodes = _sizeCodes || []
      data.sizeNames = sizeNames || {}
      data.skc = skc || {}

      const { sizeCodes } = data
      data.sizeCodesGroup = {}
      for (let item of sizeCodes) {
        if (!data.sizeCodesGroup[item.styleCategoryId]) {
          data.sizeCodesGroup[item.styleCategoryId] = []
        }
        data.sizeCodesGroup[item.styleCategoryId].push(item)
      }
      data.sizeCodesGroup = Object.values(data.sizeCodesGroup)
      this.info = data
      this.appendStyle()
      setTimeout(() => {
        JsBarcode('#barcode', data.skc.designCode, {
          height: 32,
          width: 0.8,
          fontSize: 10,
          textPosition: 'top',
          textMargin: 1.5,
          margin: 0
        })
        this.toPrint()
      }, 0)
    },
    toPrint () {
      const vm = this
      let sl = new SlPrint({
        ids: '#print-b',
        endCallback () { }
      })
      setTimeout(function () {
        vm.$nextTick(() => {
          sl.init()
        })
      })
    },
    appendStyle () {
      var css = `
        @media print {
          @page {
            size: A4;
            margin: 5mm;
          }
      }
      `
      var head = document.head || document.getElementsByTagName('head')[0]
      var style = document.createElement('style')
      style.setAttribute('type', 'text/css')
      style.id = 'list-a'
      style.appendChild(document.createTextNode(css))
      head.appendChild(style)
    }
  }
}
</script>
<style scoped lang="scss">
.sku-print {
  width: 100%;
  .tittle {
    text-align: center;
    display: flex;
    justify-content: space-between;
    h3 {
      font-size: 28px;
      text-align: center;
      width: 60%;
    }
  }
  .info {
    padding: 20px 40px;
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    line-height: 1.5;
  }
  .print-table {
    page-break-after: always;
    table {
      border-collapse: collapse;
      border-spacing: 0;
      border: 1px solid gray;
    }
    th,
    td {
      // line-height: 1.5;
      text-align: center;
      border-top: 1px solid gray;
      border-right: 1px solid gray;
      font-size: 1pt;
    }
    th {
      padding: 1em;
    }

    td {
      padding: 0.5em;
    }
  }
  .print-table:nth-child(1) {
    td {
      text-align: left;
    }
  }
}
</style>
