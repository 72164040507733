<template>
  <div v-loading="loading">
    <el-card class="mb-2rem" shadow="never">
      <div slot="header" class="clearfix">
        <div>
          <SlContentTitle
            text="生产订单详情"
            :fontSize="titleFontSize"
            :line="false"
            :titleStyle="titleStyle"
            columnIcon
          ></SlContentTitle>
          <el-button type="text" size="medium" @click="$router.go(-1)" style="padding:0">返回</el-button>
          <div style="clear: both;float: right;">
            <el-button type="primary" size="medium" @click="exportOrderInfo()">导出订单明细</el-button>
          </div>
        </div>
      </div>
      <el-row>
        <el-col :span="4" v-for="prop in productionOrderComputedProps" :key="prop.value">
          <div style="line-height:2">
            <span class="color-text--primary mr-8px">{{prop.label}}:</span>
            <span class="color-text--minor">{{productionOrderData[prop.value]}}</span>
          </div>
        </el-col>
      </el-row>
    </el-card>
    <el-card class="mb-2rem" shadow="never">
      <div slot="header" class="clearfix">
        <SlContentTitle
          text="商品明细"
          :fontSize="titleFontSize"
          :line="false"
          :titleStyle="titleStyle"
          columnIcon
        ></SlContentTitle>
      </div>
      <SlTable
        ref="table"
        align="left"
        :selection="false"
        :border="false"
        :tableData="purchaseOrderList"
        :columns="purchaseOrderListColumns"
        :operate="true"
        :tooltip="false"
        :isEmbedTable="true"
        maxHeight="400px"
        rowKey="id"
      >
        <template #operation="{row}">
          <el-button
            class="operation-btn"
            type="text"
            @click="tableOperation('showMaterials', row)"
          >查看生产资料</el-button>
          <el-button
            class="operation-btn"
            type="text"
            @click="tableOperation('printProcessSheet', row)"
          >打印工艺单</el-button>
          <el-button
            class="operation-btn"
            type="text"
            @click="tableOperation('downloadPattern', row)"
          >下载纸样</el-button>
        </template>
      </SlTable>
    </el-card>
    <el-card v-if="showDeliveryDetail" class="mb-2rem" shadow="never">
      <div slot="header" class="clearfix">
        <SlContentTitle
          text="发货单明细"
          :fontSize="titleFontSize"
          :line="false"
          :titleStyle="titleStyle"
          columnIcon
        ></SlContentTitle>
      </div>
      <SlCardTable
        ref="cardTable"
        :data="deliveryDetailList"
        :childColumns="deliveryDetailListColumns"
        :addWrapClass="false"
        childName="detailListVoList"
      >
        <template v-slot:header="{row}">
          <ul class="display-inline-block">
            <li class="display-inline-block mr-24px">
              <span class="color-text--primary mr-8px">发货单号:</span>
              <span class="color-text--minor">{{row.deliveryOrderNumber}}</span>
            </li>
            <li class="display-inline-block mr-24px">
              <span class="color-text--primary mr-8px">装箱单号:</span>
              <span class="color-text--minor">{{row.deliveryOrderPackageNumber}}</span>
            </li>
            <li class="display-inline-block">
              <span class="color-text--primary mr-8px">物流单号:</span>
              <span class="color-text--minor">{{row.logisticsBillNumber}}</span>
            </li>
            <li class="display-inline-block">
              <el-tag :type="row.deliveryType === 0?'success':'danger'">{{row.deliveryTypeName}}</el-tag>
            </li>
          </ul>
          <ul class="float-right">
            <li class="display-inline-block mr-24px">
              <span class="color-text--primary mr-8px">发货时间:</span>
              <span class="color-text--minor">{{row.createdAt}}</span>
            </li>
            <li class="display-inline-block">
              <span class="color-text--primary mr-8px">状态:</span>
              <el-tag>{{row.statusName}}</el-tag>
            </li>
          </ul>
        </template>
      </SlCardTable>
    </el-card>
    <!-- 打印大货工艺单 -->
    <PrintCraft ref="printCraft" />
  </div>
</template>

<script>
import OemGoodsAPI from '@api/oemGoods'
import { downloadBlobData } from '@/shared/util'
import PrintCraft from '../pendingOrderList/PrintCraft'
import axios from 'axios'

export default {
  name: 'ProductionOrderDetail',
  components: {
    PrintCraft
  },
  data () {
    return {
      loading: false,
      titleFontSize: '1.4rem',
      titleStyle: { marginBottom: '0px', display: 'inline-block' },
      purchaseOrderList: [],
      productionOrderData: {},
      productionOrderProps: [
        {
          label: '生产订单号',
          value: 'purchaseOrderNumber'
        },
        {
          label: '供应商编号',
          value: 'supplierId'
        },
        {
          label: '供应商名称',
          value: 'supplierName'
        },
        {
          label: '结算方式',
          value: 'settlementWayName'
        },
        {
          label: '供应商类型',
          value: 'supplierCategoryName'
        },
        {
          label: 'SKU总数',
          value: 'skuQuantity'
        },
        {
          label: '总订单数',
          value: 'requireQuantity'
        },
        {
          label: '总发货数',
          value: 'deliveryQuantity'
        },
        {
          label: '总入库数',
          value: 'inInventoryQuantity'
        },
        {
          label: '总少货数',
          value: 'lessStockQuantity'
        },
        {
          label: '订单总金额',
          value: 'totalAmount'
        },
        {
          label: '下单时间',
          value: 'orderTime'
        }
      ],
      deliveryDetailList: [],
      deliveryDetailListColumns: [
        {
          prop: 'skuCode',
          label: 'SKU编码'
        },
        {
          prop: 'productName',
          label: '商品名称'
        },
        {
          prop: 'attributesName',
          label: '销售属性'
        },
        {
          prop: 'supplierSkuAttr',
          label: '采购属性'
        },
        {
          prop: 'deliveryQuantity',
          label: '实际发货数量'
        },
        {
          prop: 'unpackQuantity',
          label: '拆包数量'
        },
        {
          prop: 'inInventoryQuantity',
          label: '入库数量'
        },
        {
          prop: 'lessStockQuantity',
          label: '少货数量'
        }
      ]
    }
  },
  computed: {
    productionOrderComputedProps () {
      let excludeLabels = ['总发货数', '总入库数', '总少货数']// 待接单、待发货状态的时不显示这些
      if (['0', '1'].includes(this.$route.query.status)) {
        return this.productionOrderProps.filter(item => !excludeLabels.includes(item.label))
      }
      return this.productionOrderProps
    },
    purchaseOrderListColumns () {
      let defaultColumns = [
        {
          prop: 'skuCode',
          label: 'SKU编码'
        },
        {
          prop: 'upc',
          label: 'UPC编码'
        },
        {
          prop: 'skuImage',
          label: '商品图片',
          isImg: true,
          data: {
            imgSize: '8rem'
          }
        },
        {
          prop: 'name',
          label: '商品名称'
        },
        {
          prop: 'attributesName',
          label: '销售属性'
        },
        {
          prop: 'orderTypeName',
          label: '首/返单类型'
        },
        {
          prop: 'basePlateSkuCode',
          label: '底板sku'
        },
        {
          prop: 'printedPattern',
          label: '花型号'
        },
        {
          prop: 'claimArrivalDate',
          label: '应交货时间'
        },
        {
          prop: 'realPrice',
          label: '采购单价'
        },
        {
          prop: 'requireQuantity',
          label: '订单数量'
        },
        {
          prop: 'deliveryQuantity',
          label: '实际发货数量'
        },
        {
          prop: 'unpackedQuantity',
          label: '拆包数量'
        },
        {
          prop: 'inInventoryQuantity',
          label: '入库数量'
        },
        {
          prop: 'lessStockQuantity',
          label: '少货数量'
        }
      ]
      let excludeLabels = ['实际发货数量', '拆包数量', '入库数量', '少货数量']// 待接单、待发货状态的时不显示这些
      if (['0', '1'].includes(this.$route.query.status)) {
        return defaultColumns.filter(item => !excludeLabels.includes(item.label))
      }
      return defaultColumns
    },
    showDeliveryDetail () {
      return ['2', '3', '4', '5'].includes(this.$route.query.status)
    }
  },
  watch: {},
  created () {
    this.getDetailInfo()
  },
  methods: {
    // 若有修改，要同时修改 PendingOrderList.vue
    tableOperation (type, row) {
      const { designCode, bomVersion, craftVersion, patternVersion, sizeVersion } = row
      const { href } = this.$router.resolve({
        path: '/home/oem-delivery-manage/production-materials',
        query: {
          designCode,
          bomVersion,
          craftVersion,
          patternVersion,
          sizeVersion
        }
      })
      switch (type) {
        case 'showMaterials':
          window.open(href, '_blank')
          break
        case 'printProcessSheet':
          if (!(designCode && bomVersion && craftVersion && sizeVersion)) {
            this.$message.error('存在值为空的版本号，无法打印工艺单！')
            return
          }
          OemGoodsAPI.designCraftPrint({
            designCode,
            bomVersion,
            craftVersion,
            sizeVersion
          }).then(res => {
            if (res.success) {
              const data = res.data || {}
              if (data.skc) {
                this.$refs.printCraft.show(res.data || {})
              } else {
                this.$message({
                  type: 'warning',
                  message: '工艺单无数据！'
                })
              }
            }
          })
          break
        case 'downloadPattern':
          OemGoodsAPI.designPattern({
            designCode,
            version: patternVersion
          }).then(res => {
            if (res.success) {
              const patternUrl = res.data && res.data.patternUrl
              if (patternUrl) {
                axios({
                  method: 'get',
                  url: patternUrl,
                  responseType: 'blob'
                }).then(inner => {
                  downloadBlobData(new Blob([inner.data]), `${res.data.spuCode}.prj`)
                })
              } else {
                this.$message({
                  type: 'warning',
                  message: '纸样下载的链接无效！'
                })
              }
            }
          })
          break
        default:
          break
      }
    },
    getDetailInfo () {
      this.loading = true
      OemGoodsAPI.getProductionOrderDetail({
        ...this.$route.query,
        status: parseInt(this.$route.query.status)
      }).then(res => {
        let { data = {}, data: { deliveryOrderDetailList = [], purchaseOrderItemVoList = [] } } = res
        this.purchaseOrderList = purchaseOrderItemVoList
        this.deliveryDetailList = deliveryOrderDetailList
        this.productionOrderProps.forEach(item => {
          this.productionOrderData[item.value] = data[item.value]
        })
      }).finally(() => {
        this.loading = false
      })
    },
    exportOrderInfo () {
      this.loading = true
      OemGoodsAPI.exportProductionOrderDetail({
        ...this.$route.query,
        status: parseInt(this.$route.query.status)
      }).then(res => {
        const csvData = new Blob([res], { type: 'text/csv;charset=utf-8;' })
        const csvURL = window.URL.createObjectURL(csvData)
        const tempLink = document.createElement('a')
        tempLink.href = csvURL
        tempLink.setAttribute('download', '订单详情.csv')
        tempLink.click()
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style scoped lang="scss">
.operation-btn {
  margin-left: 0 !important;
  margin-right: 0.8rem;
}
</style>
